.aqhi-style {
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 5px;
  }
  
  @media (max-width: 940px) {
    .aqhi-style {
      margin-left: 90px;
      margin-right: 90px;
    }
  }
  
  @media (max-width: 840px) {
      .aqhi-style {
        margin-left: 70px;
        margin-right: 70px;
      }
    }
  
    @media (max-width: 740px) {
      .aqhi-style {
        margin-left: 60px;
        margin-right: 60px;
      }
    }
  
    @media (max-width: 640px) {
      .aqhi-style {
        margin-left: 30px;
        margin-right: 30px;
      }
    }
  
    @media (max-width: 450px) {
      .aqhi-style {
        margin-left: 10px;
        margin-right: 10px;
      }
    }