.card-style {
    height: 120px;
    margin-top: -5px;
  }
  
  .card-style2 {
    height: 114px;
    margin-top: -5px;
  }
  
  @media screen and (max-width: 760px) {
    .card-style {
      height: 130px;
    }
    .card-style2 {
      height: 124px;
    }
  }
  