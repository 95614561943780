.header {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* @media (max-width: 990px) {
  .header {
    display: contents;
  }
} */
