.footer {
    text-align: center;
    padding: 20px 0;
    background-color: #f4f4f4;
    margin-top: 60px;
  
    background-image: url("../../imgs/word_map.png");
    background-repeat: no-repeat;
    background-position: center center;
  }
  
  .copyright {
    margin-top: 14px;
    margin-bottom: 0;
    font-size: 13px;
    opacity: 0.5;
  }
  