.text-style {
  font-size: 26px;
  text-align: center;
}

.div-style {
  background-color: #fdfde5;
  height: 160px;
  margin-top: 50px;
  margin-bottom: -60px;
}

@media (max-width: 1170px) {
  .text-style {
    font-size: 20px;
  }
  .div-style {
    height: 140px;
  }
}

@media (max-width: 900px) {
 

  .div-style {
    height: 210px;
  }
}

@media (max-width: 480px) {
 

  .div-style {
    height: 250px;
  }
}
