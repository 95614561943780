.head-title-m {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .title-m {
    text-align: center;
    font-size: 22px;
    font-weight: bold;
    color: #009874;
    margin-left: 10px;
  }
  
  .title-m2 {
    text-align: center;
    font-size: 16px;
    margin-top: 0px;
    color: #9e76b4;
    font-weight: 100;
  }
  
  hr.style1 {
    height: 10px;
    border: 0;
    box-shadow: 0 2px 2px -2px #8c8b8b inset;
  }
  
  @media (max-width: 990px) {
    /* .head-title-m {
      display: contents;
    } */
  
    .title-m {
      font-size: 16px;
    }
  
    .title-m2 {
      font-size: 13px;
    }
  
    .title2-m {
      margin-top: 5px;
    }
  }
  