@import url('https://fonts.googleapis.com/css2?family=Kanit:wght@500&display=swap');

/* General Website Style rules */
.p-char {
  font-family: 'Kanit', sans-serif;
}

.highlight0 {
  color: black;
  background-color: #ffffff;
}
.highlightG {
  color: white;
  background-color: #00e400;
}
.highlightY {
  color: black;
  background-color: #ffff00;
}
.highlightO {
  color: white;
  background-color: #ff7e00;
}
.highlightR {
  color: white;
  background-color: #ff0000;
}
.highlightP {
  color: white;
  background-color: #99004c;
}
.highlightB {
  color: white;
  background-color: #4c0026;
}
