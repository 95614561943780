.realtime {
  width: 100%;
  text-align: center;
  border: 1px solid black;
  border-collapse: collapse;
}

.li-style {
  text-decoration: none;
  color: #000;
}

.li-style:hover {
  color: rgb(120, 102, 223);
}
