@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Sans+Thai:wght@300&display=swap');

.navbar-brand {
  display: none;
  padding: 0 auto;
}

.navbar-links {
  /* position: absolute; */
  /* right: 0px; */
  width: 100%;
  padding: 10px;
}

.navbar-links ul {
  margin: 0;
  padding: 0;
  display: flex;
}

.navbar-links li {
  list-style: none;
}

.navbar-links li a {
  text-decoration: none;
  /* color: #565051; */
  color: #ffffff;
  padding: 0.5rem;
  display: block;
  text-shadow: 2px 2px #34412b;
}

/* .navbar-links li:hover { */
/* background-color: #f0e7e7; */
/* opacity: 0.5; */
/* } */

.navbar-links li:hover a {
  color: yellow;
}

.toggle-button {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
  margin-top: -5px;
}

.toggle-button .bar {
  height: 3px;
  width: 100%;
  /* background-color: rgb(15, 1, 1); */
  background-color: #ffffff;
  border-radius: 10px;
}

.toggle-button:hover {
  width: 28px;
  height: 19px;
}

.list-group {
  color: #000;
}
.list-group  li {
  list-style: none;
}

.list-group  li a {
  list-style: none;
  /* border-bottom: 1px solid #888; */
  border-bottom: 1px solid #fff;
  text-decoration: none;
  padding: 0.55rem;
  display: block;
  color: #fff;
  margin-left: -25px;
  margin-right: 20px;
}

.list-group ul:hover {
  background-color: #f0e7e7;
}

@media (max-width: 940px) {
  .navbar-brand {
    display: flex;
  }

  .toggle-button {
    display: flex;
  }

  .navbar-brand {
    padding: 5px 0;
  }

  .navbar-links {
    width: 100%;
    display: none;
  }
}
