.title-style-cr {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  margin-top: -3px;
  color: #009874;
}

.detail-style-cr {
  font-family: 'Kanit', sans-serif;
  font-size: 18px;
  text-align: center;
  color: #9e76b4;
}

.pin {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #695af5;
  z-index: 1;
}

.label1-cr {
  text-align: center;
  color: #fff;
  margin-top: 5px;
  font-weight: 600;
  font-size: 11px;
}
