.title-style {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  margin-top: -3px;
  color: #009874;
}

.pinonline {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255);
  width: 10px;
  height: 10px;
  background-color: blue;
  z-index: 1;
  /* animation: fadeInFromNone 1.7s ease-out infinite; */
}

.pinoffline {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255);
  width: 10px;
  height: 10px;
  background-color: rgb(255, 0, 0);
  z-index: 1;
  /* animation: fadeInFromNone 1.7s ease-out infinite; */
}

.pinnanogen {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(255, 255, 255);
  width: 10px;
  height: 10px;
  background-color: rgb(128, 0, 128);
  z-index: 1;
  /* animation: fadeInFromNone 1.7s ease-out infinite; */
}

/* @keyframes fadeInFromNone {
  0% {
    display: none;
    opacity: 0;
  }

  50% {
    display: block;
    opacity: 0.8;
  }

  100% {
    display: block;
    opacity: 1;
  }
} */
