.title-style {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  margin-top: -3px;
  color: #009874;
}

.detail-style {
  font-family: 'Kanit', sans-serif;
  font-size: 18px;
  text-align: center;
  color: #9e76b4;
}

.detail-style-nasa {
  font-family: 'Kanit', sans-serif;
  font-size: 16px;
  color: #9e76b4;
}

.detail-link-nasa {
  font-family: 'Kanit', sans-serif;
  font-size: 13px;
  color: #8c22c5;
}

.detail-date-nasa {
  font-family: 'Kanit', sans-serif;
  font-size: 13px;
  color: #7d7283;
  margin-top: -10px;
}

.btn-link {
  font-family: 'Kanit', sans-serif;
  font-size: 12px;
  border: 1px solid rgb(139, 137, 137);
  border-radius: 5px;
  padding: 5px;
}

.label1 {
  text-align: center;
  color: #000;
  margin-top: 5px;
  font-weight: 600;
  font-size: 11px;
}
.label2 {
  text-align: center;
  color: #fff;
  margin-top: 5px;
  font-weight: 600;
  font-size: 11px;
}

.pingreen {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #00e400;
  z-index: 1;
}

.pinyellow {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #ffff00;
  z-index: 1;
}

.pinorenge {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #ff7e00;
  z-index: 1;
}

.pinred {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #ff0000;
  z-index: 1;
}

.pinpurple {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #99004c;
  z-index: 1;
}

.pinblack {
  position: absolute;
  border-radius: 50%;
  border: 1px solid rgb(0, 0, 0);
  width: 24px;
  height: 24px;
  background-color: #4c0026;
  z-index: 1;
}

/* .pin2::after {
    position: absolute;
    content: '';
    width: 0px;
    height: 0px;
    bottom: -20px;
    left: 0.15cm;
    border: 10px solid transparent ;
    border-top: 16px solid red;
    z-index: 0;
  } */
