.head-title {
  display: flex;
  justify-content: center;
  align-items: center;
}

.title {
  text-align: center;
  font-size: 30px;
  font-weight: bold;
  color: #009874;
  margin-left: 10px;
}

@media (max-width: 990px) {
  .head-title {
    display: contents;
  }

  .title {
    margin-top: 5px;
  }
}
