.font-head {
  font-size: 20px;
  color: #728fce;
}

.font-head2 {
  font-size: 15px;
  color: #8d918d;
}

@media screen and (max-width: 760px) {
  .font-head {
    font-size: 17px;
  }

  .font-head2 {
    font-size: 12px;
  }
}
