.img-artboard {
  width: 95%;
}

.Grid-style:hover {
  opacity: 0.7;
}

@media (max-width: 900px) {
  .img-artboard {
    width: 100%;
  }
}
